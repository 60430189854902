<template>
  <ModalComp
    :show="showModal"
    :show-action-button="false"
    title-text="Reservation Details"
    @hide="fireAction('close')"
    title-icon-class="me-1 icon-receipt"
  >
    <div class="mt-2">
      <div
        v-if="!reservation.last_email_send_status"
      >
        <Message severity="error" :closable="false">
          Last email sent to this reservations owner was unsuccessful.
        </Message>
      </div>
      <div class="table-responsive">
        <table class="table">
          <tbody class="fs18">
          <tr>
            <td class="key">Name</td>
            <td class="val">{{ reservation.name }}</td>
          </tr>
          <tr>
            <td class="key">Email</td>
            <td class="val fw-bold">
              <a :href="`mailto:${reservation.email}`" class="text-secondary">
                <i class="icon-mail" />
                {{ reservation.email }}
              </a>
            </td>
          </tr>
          <tr>
            <td class="key">Phone</td>
            <td class="val fw-bold">
              <a :href="`tel:${reservation.phone}`" class="text-secondary">
                <i class="icon-phone-call" />
                {{ reservation.phone }}
              </a>
            </td>
          </tr>
          <tr>
            <td class="key">Total Person</td>
            <td class="val">{{ reservation.person }}</td>
          </tr>
          <tr>
            <td class="key">Reservation Date</td>
            <td class="val">{{ dateFormat(reservation.datetime) }}</td>
          </tr>
          <tr>
            <td class="key">Requested At</td>
            <td class="val">{{ dateFormat(reservation.created_at) }}</td>
          </tr>
          <tr>
            <td class="key">Special Note</td>
            <td class="val">{{ reservation.note }}</td>
          </tr>
          <tr
            v-if="reservation.found_on"
          >
            <td class="key">Heard On</td>
            <td class="val">{{ reservation.found_on }}</td>
          </tr>
          <tr>
            <td class="key">Status</td>
            <td class="val text-uppercase">
              <span class="me-5" v-html="renderStatus(reservation.status)" />
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </ModalComp>
</template>

<script>
import ModalComp from '@/components/Util/ModalComp';
import { reactive, toRefs, watch } from 'vue';
import { renderStatus, dateFormat } from '@/Mixins/appHelper';
import Message from 'primevue/message';

export default {

  name: 'ReservationAction',
  components: { ModalComp, Message },

  props: {
    data: {
      type: Object
    },
    showData: {
      type: Boolean,
    }
  },

  setup (props, { emit }) {

    const state = reactive({
      reservation: props.data,
      showModal: false,
    });

    const fireAction = (event) => emit(event);

    watch(() => props.showData, (data) => state.showModal = data);
    watch(() => props.data, (data) => state.reservation = data);

    return {
      ...toRefs(state),
      fireAction,
      renderStatus,
      dateFormat
    };
  },
};
</script>

<style scoped>
.key {
  font-weight: bold;
}
.alert {
  font-size: 15px;
}
.table {
  font-size: 16px;
}
</style>
