<template>

  <div class="row">

    <div class="col-md-12">
      <div class="card">
        <div class="card-header card-header-with-btn bg-light">
          <div class="card-title">Reservation</div>
          <SwitchComp
            name="order-filter"
            :checked="state.filterEnabled"
            enable-text="Filter Enabled"
            disable-text="Filter Disabled"
            @change="handleFilterToggle"
          />
        </div>
        <div class="card-body" v-loading="state.loading">

          <FadeSlideAnimation>
            <div v-if="state.filterEnabled" class="filters-panel">

              <div class="filters-controls row">
                <div class="form-group col-sm-12 col-md-12 col-lg-3">
                  <label>Customer</label>
                  <input
                    type="text"
                    class="form-control fs15"
                    placeholder="Customer Name"
                    v-model="state.filters.customer"
                    @keyup="triggerFilter"
                  />
                </div>
                <div class="form-group col-sm-6 col-md-6 col-lg-4">
                  <div class="row">
                    <div class="col-md-9">
                      <label>Reserved Place Date Range</label>
                      <Datepicker
                        v-model="state.filters.reservedPlaceDate"
                        @update:modelValue="triggerFilter"
                        placeholder="Start Date - End Date"
                        :format="formatDateRanger"
                        range>
                      </Datepicker>
                    </div>
                    <div class="col-md-3">
                      <button
                        type="button"
                        class="btn btn-success mt-4 text-nowrap fs15 today-btn"
                        @click="filterSetToday(true)"
                      >
                        Today
                      </button>
                    </div>
                  </div>
                </div>
                <div class="form-group col-sm-6 col-md-6 col-lg-4">
                  <div class="row">
                    <div class="col-md-9">
                      <label>Reserved For Date Range</label>
                      <Datepicker
                        v-model="state.filters.reservedForDate"
                        @update:modelValue="triggerFilter"
                        placeholder="Start Date - End Date"
                        :format="formatDateRanger"
                        range>
                      </Datepicker>
                    </div>
                    <div class="col-md-3">
                      <button
                        type="button"
                        class="btn btn-primary mt-4 text-nowrap fs15 today-btn"
                        @click="filterSetToday(false)"
                      >
                        Today
                      </button>
                    </div>
                  </div>
                </div>
                <div class="form-group col-sm-10 col-md-9 col-lg-3">
                  <label>Status</label>
                  <select
                    class="form-control fs15"
                    v-model="state.filters.status"
                    @change="triggerFilter"
                  >
                    <option selected value="">Choose...</option>
                    <option
                      v-for="status in state.statuses"
                      :key="status"
                    >
                      {{ status }}
                    </option>
                  </select>
                </div>
                <div class="form-group col-sm-2 col-md-3 col-lg-auto">
                  <button
                    type="button"
                    class="btn btn-secondary mt-4 text-nowrap fs15"
                    @click="resetFilter"
                  >
                    Reset
                  </button>
                </div>
              </div>

              <div class="filters-summery row">
                <div class="col fs20">
                  Total <strong>{{ state.filterTotalPeople }}</strong> persons in
                  <strong>{{ state.filterTotalReservations }}</strong> reservations
                </div>
              </div>

            </div>
          </FadeSlideAnimation>

          <ItemTable
            v-loading="state.filterLoading"
            :data="state.filteredReservations"
            :columns="columnMap"
            :show-actions="isAuthorized(permissionsGroup.reservation)"
            show-export
            export-file-name="Reservations"
          >
            <template #datetime="{item}">
              <span>{{ dateFormat(item.datetime) }}</span>
            </template>

            <template #status="{item}">
              <span
                class="cursor-pointer"
                v-html="renderMappedStatus(item)"
                @click="handleSelectedOrder(item, 'update')"
              ></span>
            </template>

            <template #action="{item}" v-if="isAuthorized(permissionsGroup.reservation)">
              <div class="text-nowrap">
                <ViewButton
                  v-if="isAuthorized(permissions.reservation_view)"
                  @click="handleSelectedOrder(item, 'view')"
                />

                <DeleteButton
                  v-if="isAuthorized(permissions.reservation_delete)"
                  @click="handleSelectedOrder(item, 'delete')"
                />
              </div>
            </template>

          </ItemTable>
        </div>
      </div>
    </div>

  </div>

  <reservation-action
    :data="state.selectedItem"
    :show-data="state.showAction"
    @close="handleShowAction"
  />

  <StatusUpdateAction
    v-if="state.showStatusAction"
    :current-option="state.selectedItem.status"
    :current-item="state.selectedItem"
    title="Update Reservation Status"
    :options="state.statuses"
    :show-action="state.showStatusAction"
    action-type="reservation"
    @cancelled="handleShowStatusAction"
    @updated="handleStatusUpdate"
  />

</template>

<script>
import { useStore } from 'vuex';
import { computed, onMounted, reactive, watch } from 'vue';
import ItemTable from '@/components/Util/ItemTable';
import { dateFormat, msgBox, renderStatus } from '@/Mixins/appHelper';
import ReservationAction from '@/components/Reservation/ReservationAction';
import StatusUpdateAction from '@/components/Util/StatusUpdateAction';
import FadeSlideAnimation from '@/components/Util/Animations/FadeSlideAnimation';
import Toaster from '@/utils/Toaster';
import permissions, { permissionsGroup } from '@/utils/permissions';
import { setDateTime, formatDateRanger } from '@/utils/Helper';
import SwitchComp from '@/components/Util/SwitchComp';
import ViewButton from '@/components/Util/ListActions/ViewButton';
import DeleteButton from '@/components/Util/ListActions/DeleteButton';
import SweetAlert from '@/utils/SweetAlert';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

export default {
  components: {
    Datepicker,
    DeleteButton,
    ViewButton,
    StatusUpdateAction,
    ReservationAction,
    ItemTable,
    FadeSlideAnimation,
    SwitchComp,
  },
  setup () {
    let columnMap = [
      { field: 'name', header: 'Customer Name', sortable: true },
      { field: 'person', header: 'Person', sortable: true },
      { field: 'phone', header: 'Phone', sortable: true },
      { field: 'datetime', header: 'Requested', sortable: true },
      { field: 'status', header: 'Status', sortable: true },
    ];
    const store = useStore();

    const state = reactive({
      loading: computed(() => store.state.reservation.loading),
      reservation: computed(() => store.getters['reservation/reservation']),
      statuses: computed(() => store.getters['reservation/statuses']),
      permission: computed(() => store.getters['reservation/permissions']),

      selectedItem: {},
      showAction: false,
      showStatusAction: false,

      filterEnabled: true,
      filterLoading: false,
      filters: {
        customer: '',
        reservedForDate: '',
        reservedPlaceDate: '',
        status: 'confirmed'
      },
      filterTotalReservations: 0,
      filterTotalPeople: 0,
      filteredReservations: [],
    });

    const handleShowAction = () => state.showAction = !state.showAction;

    const handleShowStatusAction = () => state.showStatusAction = !state.showStatusAction;

    const handleDeleteReservation = async (id) => {

      try {
        let response = await store.dispatch('reservation/deleteReservation', id);

        Toaster.successAlt({
          message: response.data.message || 'Reservation Successfully Deleted'
        });

      } catch (e) {

        Toaster.error({
          message: e.message || 'Something went wrong'
        });

      }
    };

    const handleSelectedOrder = (item, action) => {

      state.selectedItem = item;

      if (action === 'update') {
        handleShowStatusAction();
      }

      if (action === 'view') {
        handleShowAction();
      }

      if (action === 'delete') {

        SweetAlert.confirmError({
          text: 'This will delete this reservation permanently',
          preConfirm: async () => await handleDeleteReservation(state.selectedItem.id)
        });
      }

    };

    const getReservations = async () => {

      const reservationItems = store.getters['reservation/reservation'];

      if (reservationItems.length && store.state.reservation.dataFetched) return;

      await store.dispatch('reservation/getReservations');
    };

    const handleStatusUpdate = (reservation) => {
      // close status popup
      handleShowStatusAction();

      // if (!reservation) return;

    };

    const resetFilter = () => {
      state.filterLoading = true;

      state.filters = {
        customer: '',
        reservedForDate: '',
        reservedPlaceDate: '',
        status: ''
      };

      state.filteredReservations = [...state.reservation];

      setTimeout(() => state.filterLoading = false, 600);
    };

    const filterSetToday = (isReservedPlacedDate = false) => {

      const today = new Date();

      if (isReservedPlacedDate) {

        state.filters.reservedPlaceDate = [today, today];

      } else {

        state.filters.reservedForDate = [today, today];

      }

      triggerFilter();
    };

    const triggerFilter = () => {
      state.filterLoading = true;

      let filteredData = [...state.reservation];

      for (const filter in state.filters) {

        const filterValue = state.filters[filter];

        switch (filter) {
          case 'customer':

            if (!filterValue.length) break;

            filteredData = filteredData.filter(d => d.name.toLowerCase().includes(filterValue.toLowerCase()));
            break;

          case 'status':

            if (!filterValue.length) break;

            filteredData = filteredData.filter(d => d.status == filterValue);
            break;

          case 'reservedPlaceDate':

            const reservedPlaceDate = state.filters.reservedPlaceDate;

            if (!reservedPlaceDate) break;

            const reservedPlaceTimes = {
              from: reservedPlaceDate[0],
              to: reservedPlaceDate[1],
            };
            const reservationPlaceStart = setDateTime(new Date(reservedPlaceTimes.from), 0, 0, 0);
            const reservationPlaceEnd = setDateTime(new Date(reservedPlaceTimes.to), 23, 59, 59);

            filteredData = filteredData.filter(d => {
              return (
                (new Date(d.created_at).getTime() >= new Date(reservationPlaceStart).getTime()) &&
                (new Date(d.created_at).getTime() <= new Date(reservationPlaceEnd).getTime())
              );
            });
            break;

          case 'reservedForDate':

            const reservedForDate = state.filters.reservedForDate;

            if (!reservedForDate) break;

            const reservationTimes = {
              from: reservedForDate[0],
              to: reservedForDate[1],
            };
            const reservationStart = setDateTime(new Date(reservationTimes.from), 0, 0, 0);
            const reservationEnd = setDateTime(new Date(reservationTimes.to), 23, 59, 59);

            filteredData = filteredData.filter(d => {
              return (
                (new Date(d.datetime).getTime() >= new Date(reservationStart).getTime()) &&
                (new Date(d.datetime).getTime() <= new Date(reservationEnd).getTime())
              );
            });
            break;

          default:
            break;
        }

      }

      state.filteredReservations = filteredData;

      setTimeout(() => state.filterLoading = false, 600);
    };

    const renderMappedStatus = (item) => {
      const itemStatus = item?.status;

      return renderStatus(itemStatus, 'p-2 fs15');
    };

    const handleFilterToggle = (status) => {
      state.filterEnabled = status;

      //reset filter when disabling filter
      if (!status) {
        resetFilter();
      }
    };

    // re-calculate total persons when filtered reservation changes
    watch(() => state.filteredReservations, (nv) => {
      state.filterTotalReservations = nv.length;
      const total = nv.reduce((pv, cv) => {
        return (pv + parseInt(cv.person));
      }, 0);

      state.filterTotalPeople = total;
    });

    // re run filters when reservations changes
    watch(() => state.reservation, () => {
      triggerFilter();
    });

     watch(() => [state.filters.reservedForDate], nv => {
        triggerFilter();
    });

    // LifeCycle Hook
    onMounted(async () => {
      await getReservations();
      await triggerFilter();

      // reset new counts
      store.dispatch('app/resetNewReservationCount');
    });

    return {
      setDateTime,
      formatDateRanger,
      state,
      columnMap,
      renderMappedStatus,
      dateFormat,
      handleSelectedOrder,
      handleShowAction,
      handleShowStatusAction,
      handleStatusUpdate,
      resetFilter,
      filterSetToday,
      triggerFilter,
      handleFilterToggle,
      permissionsGroup,
      permissions
    };
  }
};
</script>

<style lang="scss">
.el-button {
  display: initial;
}

.filters-panel {
  margin: 0 -8px;

  .filters-controls {
    background-color: #dddddd;
    padding: 5px 15px;
  }

  .filters-summery {
    background-color: #ededed;
    padding: 5px 15px;
  }
}

.today-btn {
  margin-left: -24px;
  height: 36px;
  border: none;
  border-radius: 0;
}
input.dp__pointer.dp__input.dp__input_icon_pad.dp__input_focus {
  border-radius: 0;
  border: none;
}

@media screen and (max-width: 576px) {
  .filters-panel button {
    display: block;
    width: 100%;
  }
}
</style>
